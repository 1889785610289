body {
    font-family: 'Quicksand', sans-serif;
    overflow-x: hidden;
    margin: 0;
    color: var(--text-color-2)
}

a {
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.fintrip-toast-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(1, 1, 1, .12);
    padding: 50px;
    z-index: 1211;
}

.fintrip-toast-container .toast-success {
    background: #15CD72;
    padding: 15px 40px 15px 15px;
    color: white;
    border-radius: 4px;
    font-size: 15px;
    position: relative;
    margin-bottom: 15px;
}

.fintrip-toast-container .toast-error {
    background: var(--red-color);
    padding: 15px 40px 15px 15px;
    color: white;
    border-radius: 4px;
    font-size: 15px;
    position: relative;
    margin-bottom: 15px;
}


.col-lg-4,
.col-md-6,
.col-xs-12,
.col-lg-3,
.col-lg-9 {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    width: 100%;
}



.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow-y: auto;
    outline: 0;
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    -webkit-transform: translate(0, -25%);
    transform: translate(0, -25%);
}

.modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 6px 0;
}

.modal-body .light-group {
    margin-top: 2px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0;
}

.modal-body .light-group .form-element {
    padding: 8px 18px !important;
}

.modal-body .light-group label {
    margin-bottom: 6px !important;
}

.modal-container {
    background: rgba(22, 22, 22, .5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 12;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-container .item_container {
    z-index: 33;
}

.modal-container .modal-area {
    width: 650px;
    background: var(--white-color);
    position: relative;
}

.modal-container .modal-area .contain {
    width: 100%;
    height: calc(80vh - 125px);
    overflow-y: auto;
    padding: 20px;
}

.modal-container .modal-area .contain p.title {
    font-size: 19px;
    font-weight: 600;
    margin: 0;
}

.modal-container .modal-area .footer .save {
    background: var(--primary-color);
    padding: 8px 45px;
    font-size: 15px;
    text-transform: uppercase;
    color: var(--white-color);
    margin: 0 12px;
    font-weight: 600;
    border: 2px solid var(--primary-color);
    box-sizing: border-box;
    cursor: pointer;
}

.modal-container .modal-area .footer .cancel {
    border: 2px solid #60769f;
    padding: 8px 25px;
    cursor: pointer;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
}

.modal-container .modal-area .header {
    height: 65px;
    padding: 18px;
    position: relative;
}

.modal-container .modal-area .header h2 {
    font-weight: 600;
    margin: 0;
    font-size: 19px;
    line-height: 30px;
    text-transform: uppercase;
}

.modal-container .modal-area .header span {
    position: absolute;
    right: 24px;
    top: 14px;
    font-size: 21px;
    cursor: pointer;
    font-weight: 400;
}

.modal-container .modal-area .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(241, 241, 241, .7);
    height: 60px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

.modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, .2);
    border-radius: 2px;
    outline: 0;
    padding: 18px;
}

.modal-close {
    position: absolute;
    right: 18px;
    top: 12px;
}


.modal-footer {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 12px 0 6px;
}

.modal-footer .cancel {
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    padding: 6px 25px;
    cursor: pointer;
    font-size: 13px;
    text-transform: uppercase;
    margin: 0 12px;
    font-weight: 600;
}

.modal-footer .save {
    background: var(--primary-color);
    padding: 6px 45px;
    font-size: 13px;
    text-transform: uppercase;
    color: white;
    border: 2px solid var(--primary-color);
    font-weight: 600;
    box-sizing: border-box;
    margin: 0;
    cursor: pointer;
}

.modal-dialog {
    position: relative;
    max-width: 550px;
    margin: 1.75rem auto;
    pointer-events: none;
}

.modal-dialog h5 {
    font-size: 21px;
}

.modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 12px;
}

.modal-title {
    margin: 6px 0 0 !important;
    font-size: 33px;
    font-weight: 400;
    font-family: avenir, proxima-nova, "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.5;
}


:root {
    --primary-color: #574fdf;
    --background-color: #f2f4f6;
    --text-color: rgba(0, 15, 50, .75);
    --text-color-2: rgba(0, 15, 50, .65);
    --text-color-3: rgba(0, 15, 50, .55);
    --background-color-dark: #100F46;
    --red-color: #DB466D;
    --typeform-text-color: #FFE164;
}

.plr-15 {
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
}


.pull--100 {
    position: relative;
    top: -100px;
    margin-bottom: -100px;
}

.pull--125 {
    position: relative;
    margin-top: -125px;
}

.pull-70 {
    margin-top: -70px;
}

.pull-50 {
    margin-top: -50px;
}

.pull-30 {
    margin-top: -30px;
}

.push--125 {
    position: relative;
    bottom: -125px;
}


.color-text {
    color: var(--text-color);
}

.color-white {
    color: white;
}

h2 {
    font-weight: 800;
}

.color-text-medium {
    color: var(--text-color-2);
}

.color-text-light {
    color: var(--text-color-3);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

.border-box {
    box-sizing: border-box;
    ;
}



.font-900 {
    font-weight: 900;
}

.font-800 {
    font-weight: 900;
}

.font-700 {
    font-weight: 700;
}

.font-500 {
    font-weight: 500;
}

.font-300 {
    font-weight: 300;
}


.relative {
    position: relative;
}


.mt-auto {
    margin-top: auto;
}

.ptb-40 {
    padding: 40px 0;
}

.plr-10 {
    padding: 0px 10px;
}

.pl-30 {
    padding-left: 30px;
}

.pr-50 {
    padding-right: 50px;
}

.mb-5 {
    margin-bottom: 5px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-90 {
    margin-top: 90px;
}

.mb-90 {
    margin-bottom: 90px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mr-5 {
    margin-right: 5px;
}

.ml-5 {
    margin-left: 5px;
}

.ml-10 {
    margin-left: 10px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-50 {
    margin-left: 50px;
}

.mr-20 {
    margin-right: 20px;
}

.fs-18 {
    font-size: 18px;
    cursor: pointer;
}

.fs-14 {
    font-size: 14px;
    cursor: pointer;
}

.fs-19 {
    font-size: 19px;
}

.fs-16 {
    font-size: 16px;
    cursor: pointer;
}

.fs-22 {
    font-size: 22px;

}

.fs-27 {
    font-size: 27px;

}

.fs-33 {
    font-size: 33px;

}

.fs-39 {
    font-size: 39px;

}

.header-common {
    padding-top: 50px;
    margin-bottom: 20px !important;
    font-size: 30px !important;
    padding-left: 2px;
    padding-right: 2px;
}

.container-size {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 10px;
}

.horizontal {
    display: flex;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.flex-h-center {
    display: flex;
    justify-content: center;
}

.flex-v-center {
    display: flex;
    align-items: center;
}

.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

.align-self-center {
    align-self: center;
}

.point-width {
    flex: 0 0 100%;
    max-width: 100%;
}

.vertical {
    display: flex;
    flex-direction: column;
}

.horizontal-wrap {
    flex-wrap: wrap;
}

.horizontal-nowrap {
    flex-wrap: nowrap;
}

.eka-logo {
    height: 35px;
    width: 130px;
}

.text-hover:hover {
    color: var(--primary-color);
}

.layout-4 {
    flex: 0 0 100%;
    max-width: 100%;
}

.col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

.btn {
    background: var(--primary-color);
    padding: 12px 40px;
    color: #fff;
    font-weight: 600;
    box-shadow: 1px 1px 1px rgba(1, 1, 1, .1);
    transition: all ease 0.2s;
    border-radius: 3px;
    cursor: pointer;
    border: none;
    font-size: 16px;
    display: inline-block;
}

.btn:hover {
    background: #7551e9;
}

.dotted {
    z-index: 2;
    position: relative;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAIUlEQVQoU2Pk4tL2/fbt6mYGAoCRkAKY/KhCvCFFdPAAAFrzBAsWVUIJAAAAAElFTkSuQmCC) repeat;
}

.grayscale {
    filter: grayscale(100%);
}

@media (max-width: 768px) {
    .none-mobile {
        display: none;
    }
}

@media (min-width: 768px) {

    .point-width {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .ptb-70 {
        padding: 70px 0px;
    }

    .grayscale {
        filter: grayscale(100%);
        transition: all 200ms linear;
        opacity: 0.7;
    }

    .grayscale:hover {
        filter: grayscale(0);
        opacity: 1;
    }

    .header-common {
        padding-top: 0px;
        margin-bottom: 50px !important;
        font-size: 33px !important;

    }
}

@media (min-width: 1024px) {

    .ptb-70 {
        padding: 70px 0px;
    }

    .horizontal-wrap {
        flex-wrap: nowrap;
    }

    .eka-logo {
        height: 35px;
        width: 130px;
    }

    .point-width {
        flex: 0 0 33%;
        max-width: 33%;
    }

    .layout-4 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-5 {
        flex: 0 0 41.66%;
        max-width: 41.66%;
    }

    .col-lg-4 {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        flex: 0 0 58.33%;
        max-width: 58.33%;

    }

    .col-lg-8 {
        flex: 0 0 66.66%;
        max-width: 66.66%;
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }


    .container-size {
        max-width: 1400px;
        margin: 0px auto;
    }

}

.departure_date {
    border-bottom: 1px solid #dedede;
}

.departure_date .SingleDatePickerInput__withBorder {
    border: none;
}

.displayNone {
    display: none
}

.displayBlock {
    display: block;
}

.departure_date .DateInput {
    width: 200;
}


.departure_date .DateInput_input {
    padding: 5px 0px 2px;
    font-size: 16px;
    font-weight: 500;
}

.CalendarDay__selected {
    background-color: rgb(99, 103, 250);
    border: 1px double rgb(99, 103, 250);
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid rgb(99, 103, 250)
}

.DateInput_input__focused {
    border-bottom: 0px solid #dedede;
}

