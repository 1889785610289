.privacy-title {
    font-size: 44px;
}

.privacy-desc {
    font-size: 24px;
    margin-top: 8px;
    font-weight: 500;
}


@media(max-width:600px) {
    .privacy-title {
        font-size: 26px;
    }
    
    .privacy-desc {
        font-size: 16px;
        margin-top: 8px;
        font-weight: 500;
    }
    
}