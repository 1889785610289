.typewriter {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation:typing 2.5s steps(40, end);
    display: inline-block;
}
.blink{
    border-right: 1px solid white;
    animation: blink-carret;
}

/* The typing effect */
@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
}

.hoverable{
    cursor: pointer;
}
.hoverable:hover img{
    display: block;
}

.hoverable img{
    display: none;
}
