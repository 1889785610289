

    .blogs_big_container{
        display: flex;
        width: 100%;
     }

    .filterTheme{
        display:'flex';
        margin-left: -80px;
    }

@media (max-width: 768px) {
    
    .blogs_big_container{
        display: flex;
        width: 100%;
        flex-direction: column
    }

    .filterTheme{
        display:flex;
        margin-left: 0px;
        justify-content: center;
    }
    
}


